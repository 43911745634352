import React from 'react';
import './HomePage.scss'

const HomePage = () => {


  return (
    <div className='homepage-wrapper'>
      <div className='homepage-head'>
        <h1 className='homepage-title'>sonicsketch visualizer</h1>
        <h2 className='homepage-subtitle'>To start, choose a visualization from the list on the left</h2>
      </div>
      <div className='homepage-content'>
        <p>A project developed by <a href='https://twitter.com/NeriosLamaj' target='_blank' rel="noopener noreferrer">nerios</a>. If you want to support this project, you can <a href='https://www.buymeacoffee.com/nerios.art' target='_blank' rel="noopener noreferrer">buy me a beer</a>!</p>
        <p>sonicsketch V2 coming soon</p>
      </div>
    </div>
  );
}

export default HomePage;