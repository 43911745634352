import React from 'react';

const LogoIcon = () => {
  return (
    <svg className="logo" viewBox="0 0 480 100" xmlns="http://www.w3.org/2000/svg">
      <path d="M50 0C22.3858 0 0 22.3858 0 50H25C25 36.1929 36.1929 25 50 25V0Z" />
      <path d="M99 0C71.3858 0 49 22.3858 49 50H74C74 36.1929 85.1929 25 99 25V0Z" />
      <path d="M49 100C76.6142 100 99 77.6142 99 50H74C74 63.8071 62.8071 75 49 75L49 100Z" />
      <path d="M0 100C27.6142 100 50 77.6142 50 50H25C25 63.8071 13.8071 75 2.18557e-06 75L0 100Z" />
      <path d="M468.662 28.196C472.379 28.196 475.167 29.426 477.026 31.886C478.885 34.346 479.814 37.79 479.814 42.218V70.016C479.814 70.2893 479.705 70.5353 479.486 70.754C479.322 70.918 479.103 71 478.83 71H469.236C468.963 71 468.717 70.918 468.498 70.754C468.334 70.5353 468.252 70.2893 468.252 70.016V43.12C468.252 41.5347 467.924 40.3047 467.268 39.43C466.667 38.5553 465.765 38.118 464.562 38.118C463.305 38.118 462.321 38.5827 461.61 39.512C460.954 40.3867 460.626 41.644 460.626 43.284V70.016C460.626 70.2893 460.517 70.5353 460.298 70.754C460.134 70.918 459.915 71 459.642 71H450.048C449.775 71 449.529 70.918 449.31 70.754C449.146 70.5353 449.064 70.2893 449.064 70.016V14.584C449.064 14.3107 449.146 14.092 449.31 13.928C449.529 13.7093 449.775 13.6 450.048 13.6H459.642C459.915 13.6 460.134 13.7093 460.298 13.928C460.517 14.092 460.626 14.3107 460.626 14.584V31.394C460.626 31.558 460.681 31.64 460.79 31.64C460.954 31.64 461.145 31.5307 461.364 31.312C463.113 29.2347 465.546 28.196 468.662 28.196Z" />
      <path d="M428.433 71.656C423.623 71.656 419.823 70.426 417.035 67.966C414.302 65.506 412.935 62.144 412.935 57.88V41.972C412.935 37.8173 414.302 34.4827 417.035 31.968C419.823 29.4533 423.623 28.196 428.433 28.196C433.244 28.196 437.016 29.426 439.749 31.886C442.537 34.346 443.931 37.544 443.931 41.48V42.956C443.931 43.2293 443.822 43.4753 443.603 43.694C443.439 43.858 443.221 43.94 442.947 43.94L433.353 44.35C432.697 44.35 432.369 44.022 432.369 43.366V42.382C432.369 41.1793 431.987 40.168 431.221 39.348C430.511 38.528 429.581 38.118 428.433 38.118C427.231 38.118 426.274 38.5553 425.563 39.43C424.853 40.3047 424.497 41.4527 424.497 42.874V56.978C424.497 58.3993 424.853 59.5473 425.563 60.422C426.274 61.2967 427.231 61.734 428.433 61.734C429.581 61.734 430.511 61.324 431.221 60.504C431.987 59.6293 432.369 58.618 432.369 57.47V56.404C432.369 56.1307 432.451 55.912 432.615 55.748C432.834 55.5293 433.08 55.42 433.353 55.42L442.947 55.502C443.221 55.502 443.439 55.6113 443.603 55.83C443.822 55.994 443.931 56.2127 443.931 56.486V58.372C443.931 62.4173 442.537 65.6427 439.749 68.048C437.016 70.4533 433.244 71.656 428.433 71.656Z" />
      <path d="M408.834 35.986C408.834 36.2593 408.725 36.5053 408.506 36.724C408.342 36.888 408.123 36.97 407.85 36.97H403.258C402.985 36.97 402.848 37.1067 402.848 37.38V56.896C402.848 58.4813 403.149 59.5747 403.75 60.176C404.351 60.7773 405.253 61.0507 406.456 60.996H407.03C407.303 60.996 407.522 61.1053 407.686 61.324C407.905 61.488 408.014 61.7067 408.014 61.98V70.016C408.014 70.2893 407.905 70.5353 407.686 70.754C407.522 70.918 407.303 71 407.03 71H403.75C399.759 71 396.753 70.344 394.73 69.032C392.707 67.6653 391.696 65.096 391.696 61.324V37.38C391.696 37.1067 391.559 36.97 391.286 36.97H388.58C388.307 36.97 388.061 36.888 387.842 36.724C387.678 36.5053 387.596 36.2593 387.596 35.986V29.836C387.596 29.5627 387.678 29.344 387.842 29.18C388.061 28.9613 388.307 28.852 388.58 28.852H391.286C391.559 28.852 391.696 28.7153 391.696 28.442V19.34C391.696 19.0667 391.778 18.848 391.942 18.684C392.161 18.4653 392.407 18.356 392.68 18.356H401.864C402.137 18.356 402.356 18.4653 402.52 18.684C402.739 18.848 402.848 19.0667 402.848 19.34V28.442C402.848 28.7153 402.985 28.852 403.258 28.852H407.85C408.123 28.852 408.342 28.9613 408.506 29.18C408.725 29.344 408.834 29.5627 408.834 29.836V35.986Z" />
      <path d="M384.593 52.55C384.593 52.8233 384.484 53.0693 384.265 53.288C384.101 53.452 383.883 53.534 383.609 53.534H365.569C365.296 53.534 365.159 53.6707 365.159 53.944V56.978C365.159 58.3447 365.515 59.4927 366.225 60.422C366.991 61.2967 367.947 61.734 369.095 61.734C370.134 61.734 370.981 61.406 371.637 60.75C372.293 60.0393 372.731 59.1647 372.949 58.126C373.113 57.5793 373.469 57.306 374.015 57.306L383.445 57.798C383.719 57.798 383.937 57.9073 384.101 58.126C384.32 58.29 384.402 58.536 384.347 58.864C383.965 63.0733 382.489 66.2713 379.919 68.458C377.405 70.59 373.797 71.656 369.095 71.656C364.23 71.656 360.431 70.4533 357.697 68.048C354.964 65.588 353.597 62.1987 353.597 57.88V41.972C353.597 37.8173 354.964 34.4827 357.697 31.968C360.485 29.4533 364.285 28.196 369.095 28.196C373.906 28.196 377.678 29.4533 380.411 31.968C383.199 34.4827 384.593 37.8173 384.593 41.972V52.55ZM369.095 38.118C367.947 38.118 366.991 38.5553 366.225 39.43C365.515 40.3047 365.159 41.4253 365.159 42.792V45.99C365.159 46.2633 365.296 46.4 365.569 46.4H372.621C372.895 46.4 373.031 46.2633 373.031 45.99V42.792C373.031 41.4253 372.649 40.3047 371.883 39.43C371.173 38.5553 370.243 38.118 369.095 38.118Z" />
      <path d="M320.161 71C319.888 71 319.642 70.918 319.423 70.754C319.259 70.5353 319.177 70.2893 319.177 70.016V14.584C319.177 14.3107 319.259 14.092 319.423 13.928C319.642 13.7093 319.888 13.6 320.161 13.6H329.755C330.029 13.6 330.247 13.7093 330.411 13.928C330.63 14.092 330.739 14.3107 330.739 14.584V41.726C330.739 41.9447 330.794 42.0813 330.903 42.136C331.013 42.136 331.122 42.054 331.231 41.89L338.365 29.508C338.584 29.0707 338.994 28.852 339.595 28.852H349.435C349.818 28.852 350.064 28.9613 350.173 29.18C350.337 29.3987 350.31 29.672 350.091 30L341.235 43.776C341.126 43.9947 341.099 44.186 341.153 44.35L351.157 69.852C351.212 69.9613 351.239 70.098 351.239 70.262C351.239 70.754 350.939 71 350.337 71H340.415C339.869 71 339.513 70.7267 339.349 70.18L334.101 53.944C334.047 53.78 333.965 53.698 333.855 53.698C333.746 53.6433 333.664 53.698 333.609 53.862L330.903 58.126C330.794 58.3447 330.739 58.5087 330.739 58.618V70.016C330.739 70.2893 330.63 70.5353 330.411 70.754C330.247 70.918 330.029 71 329.755 71H320.161Z" />
      <path d="M299.488 71.574C294.842 71.574 291.124 70.4807 288.336 68.294C285.603 66.0527 284.236 63.0733 284.236 59.356V58.782C284.236 58.5087 284.318 58.29 284.482 58.126C284.701 57.9073 284.947 57.798 285.22 57.798H294.24C294.514 57.798 294.732 57.9073 294.896 58.126C295.115 58.29 295.224 58.5087 295.224 58.782V59.028C295.224 60.176 295.634 61.1327 296.454 61.898C297.274 62.6087 298.286 62.964 299.488 62.964C300.582 62.964 301.429 62.6633 302.03 62.062C302.686 61.406 303.014 60.586 303.014 59.602C303.014 58.3993 302.495 57.47 301.456 56.814C300.418 56.1033 299.242 55.5293 297.93 55.092C296.673 54.6 295.798 54.272 295.306 54.108C292.3 53.0147 289.758 51.5113 287.68 49.598C285.658 47.63 284.646 44.8147 284.646 41.152C284.646 37.1613 285.958 34.018 288.582 31.722C291.206 29.426 294.705 28.278 299.078 28.278C303.616 28.278 307.196 29.4807 309.82 31.886C312.499 34.2913 313.838 37.544 313.838 41.644C313.838 41.9173 313.729 42.1633 313.51 42.382C313.346 42.546 313.128 42.628 312.854 42.628H304.08C303.807 42.628 303.561 42.546 303.342 42.382C303.178 42.1633 303.096 41.9173 303.096 41.644V40.988C303.096 39.8947 302.741 39.02 302.03 38.364C301.374 37.6533 300.5 37.298 299.406 37.298C298.258 37.298 297.356 37.6807 296.7 38.446C296.044 39.1567 295.716 40.004 295.716 40.988C295.716 42.3 296.263 43.3387 297.356 44.104C298.504 44.8147 300.226 45.6073 302.522 46.482C304.873 47.3567 306.868 48.2587 308.508 49.188C310.203 50.0627 311.652 51.3473 312.854 53.042C314.057 54.682 314.658 56.7593 314.658 59.274C314.658 63.046 313.264 66.0527 310.476 68.294C307.743 70.4807 304.08 71.574 299.488 71.574Z" />
      <path d="M264.754 71.656C259.943 71.656 256.144 70.426 253.356 67.966C250.622 65.506 249.256 62.144 249.256 57.88V41.972C249.256 37.8173 250.622 34.4827 253.356 31.968C256.144 29.4533 259.943 28.196 264.754 28.196C269.564 28.196 273.336 29.426 276.07 31.886C278.858 34.346 280.252 37.544 280.252 41.48V42.956C280.252 43.2293 280.142 43.4753 279.924 43.694C279.76 43.858 279.541 43.94 279.268 43.94L269.674 44.35C269.018 44.35 268.69 44.022 268.69 43.366V42.382C268.69 41.1793 268.307 40.168 267.542 39.348C266.831 38.528 265.902 38.118 264.754 38.118C263.551 38.118 262.594 38.5553 261.884 39.43C261.173 40.3047 260.818 41.4527 260.818 42.874V56.978C260.818 58.3993 261.173 59.5473 261.884 60.422C262.594 61.2967 263.551 61.734 264.754 61.734C265.902 61.734 266.831 61.324 267.542 60.504C268.307 59.6293 268.69 58.618 268.69 57.47V56.404C268.69 56.1307 268.772 55.912 268.936 55.748C269.154 55.5293 269.4 55.42 269.674 55.42L279.268 55.502C279.541 55.502 279.76 55.6113 279.924 55.83C280.142 55.994 280.252 56.2127 280.252 56.486V58.372C280.252 62.4173 278.858 65.6427 276.07 68.048C273.336 70.4533 269.564 71.656 264.754 71.656Z" />
      <path d="M237.714 24.096C235.855 24.096 234.297 23.4947 233.04 22.292C231.837 21.0347 231.236 19.4767 231.236 17.618C231.236 15.7047 231.837 14.1467 233.04 12.944C234.243 11.7413 235.801 11.14 237.714 11.14C239.627 11.14 241.158 11.7413 242.306 12.944C243.509 14.1467 244.11 15.7047 244.11 17.618C244.11 19.4767 243.509 21.0347 242.306 22.292C241.103 23.4947 239.573 24.096 237.714 24.096ZM233.04 71C232.767 71 232.521 70.918 232.302 70.754C232.138 70.5353 232.056 70.2893 232.056 70.016V29.836C232.056 29.5627 232.138 29.344 232.302 29.18C232.521 28.9613 232.767 28.852 233.04 28.852H242.634C242.907 28.852 243.126 28.9613 243.29 29.18C243.509 29.344 243.618 29.5627 243.618 29.836V70.016C243.618 70.2893 243.509 70.5353 243.29 70.754C243.126 70.918 242.907 71 242.634 71H233.04Z" />
      <path d="M214.814 28.196C218.532 28.196 221.32 29.426 223.178 31.886C225.037 34.346 225.966 37.79 225.966 42.218V70.016C225.966 70.2893 225.857 70.5353 225.638 70.754C225.474 70.918 225.256 71 224.982 71H215.388C215.115 71 214.869 70.918 214.65 70.754C214.486 70.5353 214.404 70.2893 214.404 70.016V43.12C214.404 41.5347 214.076 40.3047 213.42 39.43C212.819 38.5553 211.917 38.118 210.714 38.118C209.457 38.118 208.473 38.5827 207.762 39.512C207.106 40.3867 206.778 41.644 206.778 43.284V70.016C206.778 70.2893 206.669 70.5353 206.45 70.754C206.286 70.918 206.068 71 205.794 71H196.2C195.927 71 195.681 70.918 195.462 70.754C195.298 70.5353 195.216 70.2893 195.216 70.016V29.836C195.216 29.5627 195.298 29.344 195.462 29.18C195.681 28.9613 195.927 28.852 196.2 28.852H205.794C206.068 28.852 206.286 28.9613 206.45 29.18C206.669 29.344 206.778 29.5627 206.778 29.836V31.394C206.778 31.558 206.833 31.64 206.942 31.64C207.106 31.64 207.298 31.5307 207.516 31.312C209.266 29.2347 211.698 28.196 214.814 28.196Z" />
      <path d="M173.947 71.656C169.082 71.656 165.255 70.426 162.467 67.966C159.734 65.506 158.367 62.144 158.367 57.88V41.972C158.367 37.8173 159.761 34.4827 162.549 31.968C165.337 29.4533 169.136 28.196 173.947 28.196C178.812 28.196 182.639 29.4533 185.427 31.968C188.215 34.4827 189.609 37.8173 189.609 41.972V57.88C189.609 62.144 188.215 65.506 185.427 67.966C182.694 70.426 178.867 71.656 173.947 71.656ZM173.947 61.652C175.15 61.652 176.134 61.2147 176.899 60.34C177.664 59.4653 178.047 58.3447 178.047 56.978V42.792C178.047 41.4253 177.664 40.3047 176.899 39.43C176.134 38.5553 175.15 38.118 173.947 38.118C172.744 38.118 171.76 38.5553 170.995 39.43C170.284 40.3047 169.929 41.4253 169.929 42.792V56.978C169.929 58.3447 170.284 59.4653 170.995 60.34C171.76 61.2147 172.744 61.652 173.947 61.652Z" />
      <path d="M139.252 71.574C134.605 71.574 130.888 70.4807 128.1 68.294C125.367 66.0527 124 63.0733 124 59.356V58.782C124 58.5087 124.082 58.29 124.246 58.126C124.465 57.9073 124.711 57.798 124.984 57.798H134.004C134.277 57.798 134.496 57.9073 134.66 58.126C134.879 58.29 134.988 58.5087 134.988 58.782V59.028C134.988 60.176 135.398 61.1327 136.218 61.898C137.038 62.6087 138.049 62.964 139.252 62.964C140.345 62.964 141.193 62.6633 141.794 62.062C142.45 61.406 142.778 60.586 142.778 59.602C142.778 58.3993 142.259 57.47 141.22 56.814C140.181 56.1033 139.006 55.5293 137.694 55.092C136.437 54.6 135.562 54.272 135.07 54.108C132.063 53.0147 129.521 51.5113 127.444 49.598C125.421 47.63 124.41 44.8147 124.41 41.152C124.41 37.1613 125.722 34.018 128.346 31.722C130.97 29.426 134.469 28.278 138.842 28.278C143.379 28.278 146.96 29.4807 149.584 31.886C152.263 34.2913 153.602 37.544 153.602 41.644C153.602 41.9173 153.493 42.1633 153.274 42.382C153.11 42.546 152.891 42.628 152.618 42.628H143.844C143.571 42.628 143.325 42.546 143.106 42.382C142.942 42.1633 142.86 41.9173 142.86 41.644V40.988C142.86 39.8947 142.505 39.02 141.794 38.364C141.138 37.6533 140.263 37.298 139.17 37.298C138.022 37.298 137.12 37.6807 136.464 38.446C135.808 39.1567 135.48 40.004 135.48 40.988C135.48 42.3 136.027 43.3387 137.12 44.104C138.268 44.8147 139.99 45.6073 142.286 46.482C144.637 47.3567 146.632 48.2587 148.272 49.188C149.967 50.0627 151.415 51.3473 152.618 53.042C153.821 54.682 154.422 56.7593 154.422 59.274C154.422 63.046 153.028 66.0527 150.24 68.294C147.507 70.4807 143.844 71.574 139.252 71.574Z" />
    </svg>
  );
}

export default LogoIcon;