import React from 'react';
import { Link, useLocation } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';
import LogoIcon from './LogoIcon/LogoIcon'
import SocialIcons from './SocialIcons/SocialIcons'
import { useTracking } from '../../utils/tracking-context'
import { ALL_VISUALS } from '../../utils/all-visuals'
import './HeaderFooter.scss'

const HeaderFooter = ({ children }) => {
  const { pathname } = useLocation()
  const { logEvent } = useTracking()

  const handleHeaderClick = (category, label) => {
    logEvent({
      category,
      action: 'Click',
      label
    });
  }
  return (
    <>
      <div className="app-wrapper">
        <div className="sidebar-wrapper">
          <div className="sidebar-header">
            <Link to='/' onClick={() => handleHeaderClick('Logo')} className="no-style">
              <LogoIcon />
            </Link>
          </div>
          <div className='sidebar-content'>
            <h3 className='sidebar-content-title'>Visualisations</h3>
            {ALL_VISUALS.map(visual => (
              <div key={visual.path} className={`sidebar-content-item ${visual.path === pathname ? "selected" : ""}`}>
                <Link className='no-style' to={`${visual.path}`}>{visual.name}</Link>
              </div>
            ))}
          </div>
          <div className="sidebar-footer">
            <SocialIcons trackingCategory={"Footer"} />
            <div className='buy-me-a-beer'>
              <p className='buy-me-a-beer-title'>Support this project</p>
              <a className='no-style btn inverted' href='https://www.buymeacoffee.com/nerios.art' target='_blank' rel="noopener noreferrer"><span role="img" aria-label='beer'>🍺</span> Buy me a Beer</a>
            </div>
          </div>
        </div>
        <div className="main-wrapper">
          {children}
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="That's OK"
        cookieName="cookies.accepted"
        buttonClasses="btn light btn-consent"
        declineButtonClasses="btn inverted btn-consent"
        style={{ background: "#222222", color: "#bbbbbb" }}
        disableButtonStyles={true}
        enableDeclineButton
        expires={150}
      >
        <h5 style={{ lineHeight: "1", margin: "0" }}>This website uses cookies <span role="img" aria-label="description">🍪</span></h5>
      </CookieConsent>
    </>
  );
}

export default HeaderFooter;