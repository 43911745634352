import React from "react"
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage"
import VisualContainer from "./components/VisualContainer/VisualContainer";
import HeaderFooter from './components/HeaderFooter/HeaderFooter';

import "./App.scss"


const App = () => {
  return (
    <HeaderFooter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path={`/visuals/:visualId`} element={<VisualContainer />} />
      </Routes>
    </HeaderFooter>
  )
}

export default App;
