export const ALL_VISUALS = [
  {
    path: "/visuals/do-you-feel-it",
    name: "Do You Feel It?"
  },
  {
    path: "/visuals/am-boxi",
    name: "Am Boxi"
  },
  {
    path: "/visuals/so-basic",
    name: "So Basic!"
  },
  {
    path: "/visuals/magic-ball",
    name: "Magic Ball"
  }
]