import gtag, { install } from 'ga-gtag';
import React, { useEffect, createContext } from 'react';
import { useLocation } from "react-router-dom";

const TrackingID = 'G-M0L6VZ3CE0';
const TrackingContext = createContext();

function TrackingProvider(props) {
  const location = useLocation();

  const logEvent = ({ category = '', action = '', label = '' }) => {
    gtag('event', action, {
      category,
      label
    })
  }

  useEffect(() => {
    install(TrackingID)
    gtag('config', TrackingID, {
      'debug_mode': !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    });
    gtag('js', new Date());
  }, []);

  useEffect(() => {
    const url = location.pathname + location.search;
    gtag('set', 'page_path', url);
  }, [location]);

  return <TrackingContext.Provider
    value={{ logEvent }}
    {...props}
  />
}

const useTracking = () => React.useContext(TrackingContext);

export { TrackingProvider, useTracking };
