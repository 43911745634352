import React from 'react';
import { FiInstagram, FiTwitter } from 'react-icons/fi';
import { useTracking } from '../../../utils/tracking-context'

const SocialIcons = ({ trackingCategory, justify }) => {
  const { logEvent } = useTracking()
  const handleHeaderClick = (category, label) => {
    logEvent({
      category,
      action: 'Click',
      label
    });
  }
  return (

    <div className="social-links" style={{ justifyContent: justify }}>
      <a onClick={() => handleHeaderClick(trackingCategory, 'Instagram')} className="no-style" target="_blank" rel="noopener noreferrer" href="https://instagram.com/sonic_sketch"><h2><FiInstagram /></h2></a>
      <a onClick={() => handleHeaderClick(trackingCategory, 'Twitter')} className="no-style" target="_blank" rel="noopener noreferrer" href="https://twitter.com/sonic_sketch"><h2><FiTwitter /></h2></a>
    </div>
  );
}

export default SocialIcons;
