import React from 'react'
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import { TrackingProvider } from './utils/tracking-context';
import { BrowserRouter as Router } from "react-router-dom";

import * as serviceWorker from './serviceWorker';

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  (
    <Router>
      <TrackingProvider>
        <App />
      </TrackingProvider>
    </Router>
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
