import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineFullscreenExit, MdOutlineFullscreen, MdPlayArrow, MdStop } from 'react-icons/md';
import { useParams } from "react-router-dom";
import * as dat from 'dat.gui';

import './VisualContainer.scss'

const VisualContainer = () => {
  const { visualId } = useParams();
  const p5Ref = useRef(null)
  const controlsRef = useRef(null)
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isStopped, setIsStopped] = useState(false);

  useEffect(() => {
    let p5Instance;
    const gui = new dat.GUI({
      autoPlace: false,
      hideable: true,
    });
    controlsRef.current.append(gui.domElement)

    import(`../../visualizations/${visualId}/`).then(sketch => {
      p5Instance = new window.p5((p) => {
        let _W = p5Ref.current.clientWidth;
        let _H = p5Ref.current.clientHeight;

        sketch.default(p, gui, {
          isStopped,
          _W,
          _H
        })

        p.windowResized = () => {
          const isFullScreen = p.fullscreen()
          _W = isFullScreen ? window.innerWidth : p5Ref.current.clientWidth;
          _H = isFullScreen ? window.innerHeight : p5Ref.current.clientHeight;
          p.resizeCanvas(_W, _H);
        }
      }, p5Ref.current)
    });

    return () => {
      p5Instance.remove()
      gui.destroy()
      gui.domElement.remove()
    };
  }, [isStopped, visualId]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', switchFullscreenState, false);
    document.addEventListener('mozfullscreenchange', switchFullscreenState, false);
    document.addEventListener('MSFullscreenChange', switchFullscreenState, false);
    document.addEventListener('webkitfullscreenchange', switchFullscreenState, false);
    document.addEventListener('keypress', (e) => {
      if (e.key === 'F' || e.key === 'f') {
        switchFullscreen()
      }
    }, false);
  }, [])

  useEffect(() => {
    let timeout;
    p5Ref.current.onmousemove = () => {
      clearTimeout(timeout);
      controlsRef.current.classList.remove("hide")
      p5Ref.current.style.cursor = 'default'
      timeout = setTimeout(() => {
        controlsRef.current.classList.add("hide")
        p5Ref.current.style.cursor = 'none'
      }, 4000);
    }
  }, [])

  const switchFullscreen = () => {
    setIsFullscreen(prev => {
      if (prev) {
        document.exitFullscreen()
      } else {
        p5Ref.current.requestFullscreen()
      }
      return !prev
    })
  }

  const switchFullscreenState = () => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      setIsFullscreen(false)
    }
  }

  return (
    <div ref={p5Ref} className="visual-container-wrapper">
      <div ref={controlsRef} className="visual-controls-container">
        <div className="visual-controls">
          <button onClick={() => setIsStopped(prev => !prev)}>{isStopped ? <MdPlayArrow /> : <MdStop />}</button>
          <button onClick={() => switchFullscreen()}>{isFullscreen ? <MdOutlineFullscreenExit /> : <MdOutlineFullscreen />}</button>
        </div>
      </div>
    </div>
  );
}

export default VisualContainer;